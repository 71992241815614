// src/Navbar.js

import React from 'react';
import './Navbar.css';
import navLogo from './assets/logo.png';

function Navbar() {
  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="logo-container">
          <img src={navLogo} alt="Nav Logo" className="nav-logo-image" />
        </div>
        <ul className="nav-links">
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
        </ul>
      </div>
    </nav>
  );
}

export default Navbar;
