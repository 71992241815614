import React, { useState } from 'react';
import './App.css';
import Navbar from './Navbar';
import mainLogo from './assets/image.png'; // Main logo image
import dogVideo from './assets/dog.mp4';
import pumpIcon from './assets/pumpfun-icon.png';
import twitterIcon from './assets/twitter-icon.png';
import telegramIcon from './assets/telegram-icon.png';

function App() {
  const [copySuccess, setCopySuccess] = useState('');

  const walletAddress = '5MWfvU5zL2GoDGA5YRLHzQ639U2FKqpsfeBStuAvpump'; // Replace with your actual wallet address

  const copyAddress = () => {
    navigator.clipboard.writeText(walletAddress)
      .then(() => {
        setCopySuccess('Address copied to clipboard!');
        setTimeout(() => setCopySuccess(''), 3000);
      })
      .catch(() => {
        setCopySuccess('Failed to copy address');
      });
  };

  return (
    <div className="App">
      <Navbar />
      <main>
        {/* Main Logo Section */}
        <section className="logo-section starry-background" id="home">
          <div className="stars"></div>
          <div className="logo-container">
            <img src={mainLogo} alt="Main Logo" className="main-logo" />
            <header className="main-header">
              <h1>Ticker: $SPD</h1>
            </header>
          </div>
        </section>

        {/* About Section */}
        <section className="about-section" id="about">
          <div className="about-content">
            {/* Video on the left */}
            <div className="about-video">
              <video autoPlay loop playsInline controls>
                <source src={dogVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>

            {/* Text on the right */}
            <div className="about-text">
              <h2>We all need some rest $SPD</h2>
              <p>
                This dedicated police dog works tirelessly to keep our community safe.
                Even heroes need rest, and we celebrate his commitment and bravery.
              </p>
              <div className="social-links">
                <h3>Connect with Us:</h3>
                <a href="https://pump.fun/coin/5MWfvU5zL2GoDGA5YRLHzQ639U2FKqpsfeBStuAvpump" target="_blank" rel="noopener noreferrer" className="button">
                  <img src={pumpIcon} alt="Pump.Fun" /> Pump.Fun
                </a>
                {/*<a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer" className="button twitter">
                  <img src={twitterIcon} alt="Twitter" /> Twitter
                </a>
                <a href="https://t.me/yourchannel" target="_blank" rel="noopener noreferrer" className="button telegram">
                  <img src={telegramIcon} alt="Telegram" /> Telegram
                </a>*/}

                {/* Wallet Address and Copy Button */}
                <div className="wallet-section">
                  <h3>Wallet Address:</h3>
                  <p className="wallet-address">{walletAddress}</p>
                  <button className="copy-button" onClick={copyAddress} aria-label="Copy Wallet Address">
                    Copy Address
                  </button>
                  {/* Success Message */}
                  {copySuccess && <p className="copy-success">{copySuccess}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
